<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="../assets/images/logo/stlogo1.png" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--1"
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="breadcrumb-inner pt--100 pt_sm--40 pt_md--50">
              <h2 class="heading-title">About</h2>
              <ul class="page-list">
                <li v-for="(item, i) in breadcrumbs" :key="i">
                  <router-link :to="item.to" :disabled="item.disabled">{{
                    item.text
                  }}</router-link>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start About Area  -->
    <div class="about-area rn-section-gap bg_color--1">
      <div class="about-wrapper">
        <About>
          <img
            slot="thum-img"
            class="w-100"
            src="../assets/images/portfolio/3.jpg"
            alt="About Images"
          />
        </About>
      </div>
    </div>
    <!-- Start About Area  -->

    <!-- Start Finding us Area  -->
    <div class="rn-finding-us-area rn-finding-us bg_color--1">
      <div class="inner">
        <div class="content-wrapper">
          <div class="content" style="width:95%">
            <h4 class="theme-gradient">Get your favorite flooring today</h4>
            <p>
              Sun Tile is staffed with a full-time service department.  Our service crews are all highly trained.  Should the need should ever arise, we will be there to address any issue with your flooring installation.  All installations are warranted for a period of 1 year.
            </p>
            <router-link class="rn-btn btn-white" to="/contact"
              >Get Started</router-link
            >
          </div>
        </div>
        <div class="thumbnail">
          <div class="image">
    
          </div>
        </div>
      </div>
    </div>
    <!-- End Finding us Area  -->

    <!-- Start team Area  -->

    <!-- Start team Area  -->




    <Footer />
  </div>
</template>

<script>
  import Header from "../components/header/Header";
  import About from "../components/about/About";
  import CounterOne from "../components/counter/CounterOne";
  import TeamThree from "../components/team/TeamThree";
  import Testimonial from "../components/testimonial/Testimonial";
  import Brand from "../components/brand/Brand";
  import Footer from "../components/footer/Footer";
  export default {
    components: {
      Header,
      About,
      CounterOne,
      TeamThree,
      Testimonial,
      Brand,
      Footer,
    },
    data() {
      return {
        breadcrumbs: [
          {
            text: "Home",
            to: "/",
            disabled: false,
          },
          {
            text: "About",
            to: "",
            disabled: true,
          },
        ],
      };
    },
  };
</script>
